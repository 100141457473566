<template>
  <div id="page-suppliers-list">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading" class="vx-card p-6">
      <vs-table hoverFlat ref="table" :data="suppliersData" search stripe :max-items="itemsPerPage">
        
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <!-- <router-link v-if="$acl.check('superadmin')" :to="{name: 'supplierCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
          </router-link> -->
          <div class="ml-auto">
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2">{{ $t('sort') }}: </span>
                <span class="capitalize mr-2">{{ $t(current) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="opt in statusOptions" :key="opt.value" @click="current=opt.value">
                  <span>{{ $t(opt.label) }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ suppliersData.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : suppliersData.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemsPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

          </div>
        </div>

        <template slot="thead">
          <vs-th class="pr-0" sort-key="isExpress">#</vs-th>
          <vs-th class="pl-0" sort-key="code">{{ $t('fields.code') }}</vs-th>
          <vs-th class="pl-0" sort-key="name">{{ $t('fields.name') }}</vs-th>
          <!-- <vs-th class="pl-0" sort-key="username">{{ $t('fields.username') }}</vs-th> -->
          <vs-th class="hidden lg:table-cell" sort-key="phone">{{ $t('fields.phone') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="email">{{ $t('fields.email') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="company_name">{{ $t('props.company_name') }}</vs-th>
          <vs-th sort-key="isBlocked">{{ $t('fields.active') }}?</vs-th>
          <vs-th sort-key="tasks_all">{{ $t('fields.items') }}</vs-th>
          <vs-th><span class="ml-auto">{{ $t('fields.actions') }}</span></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            
            <vs-td class="pr-0">
              {{ data[indextr].id }}
            </vs-td>
            
            <vs-td class="pl-0" :data="data[indextr].code">
              {{ data[indextr].code }}
            </vs-td>
            
            <vs-td class="pl-0" :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            
            <!-- <vs-td class="pl-0" :data="data[indextr].username">
              {{ data[indextr].username }}
            </vs-td> -->

            <vs-td :data="data[indextr].phone" class="hidden lg:table-cell">
              {{ data[indextr].phone }}
            </vs-td>

            <vs-td :data="data[indextr].email" class="hidden lg:table-cell">
              {{ data[indextr].email }}
            </vs-td>

            <vs-td :data="data[indextr].company_name" class="hidden lg:table-cell">
              {{ data[indextr].company_name | capitalize }}
            </vs-td>
            
            <vs-td :data="data[indextr].isBlocked">
              <vs-chip :color="!tr.isBlocked ? 'primary' : 'danger'">
                <feather-icon v-if="!tr.isBlocked" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>

            <vs-td :data="data[indextr].orders_delivered">
              <!-- {{ data[indextr].orders_rejected }}/<strong>{{ data[indextr].orders_delivered }}</strong> -->
            </vs-td>

            <vs-td class="whitespace-no-wrap text-right">
              <feather-icon icon="EyeIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="" :title="$t('details')" @click.stop="showData(tr.uuid)" />
              <template v-if="$acl.check('superadmin')" >
                <feather-icon icon="EditIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('edit')" @click.stop="editData(tr.uuid)" />
                <feather-icon v-if="!tr.isBlocked" icon="LockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-danger' ]"  class=" ml-2" :title="$t('block')" @click.stop="confirmStatusChange(tr.uuid, tr.name, tr.isBlocked)" />
                <feather-icon v-else icon="UnlockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('unblock')" @click.stop="confirmStatusChange(tr.uuid, tr.name, tr.isBlocked)" />
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
              </template>
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      itemsPerPage: 15,
      isMounted: false,
      suppliers: [],
      current: 'all',
      statusOptions: [
        { label: 'all', value: 'all' },
        { label: 'active', value: 'active' },
        { label: 'blocked', value: 'blocked' }
      ],
      changingSupplier: {},
      isLoading: true
    }
  },
  computed: {
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems ()  { return this.$refs.table ? this.$refs.table.queriedResults.length : this.suppliersData.length },
    suppliersData () {
      switch (true) {
      case (this.current === 'all'): return this.suppliers
      case (this.current === 'active'): return this.suppliers.filter(usr => !usr.isBlocked)
      case (this.current === 'blocked'): return this.suppliers.filter(usr => usr.isBlocked)
      }
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get('/suppliers')
        .then((response) => { 
          this.suppliers = response.data.data
          this.isLoading = false
        })
        .catch((error)   => {
          this.isLoading = false
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    editData (id) { this.$router.push(`/suppliers/${id}/edit`) },
    showData (id) { this.$router.push(`/suppliers/${id}`) },
    confirmStatusChange (id, name, status) {
      this.changingSupplier = {id, status}
      this.$vs.dialog({
        type: 'confirm',
        color: status ? 'primary' : 'danger',
        title: `${status ? this.$t('unblock') : this.$t('block')}`,
        text: `${status ? this.$t('confirmUnblock') : this.$t('confirmBlock')} "${name}"`,
        accept: this.changeStatus,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    async changeStatus () {
      await this.$http.patch(`/suppliers/edit/${this.changingSupplier.id}`, {
        isBlocked: !this.changingSupplier.status
      }).then(() => {
        // console.log(response)
        this.fetchData()
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: `${this.$t('status')} ${this.$t('notify.sUpdated')}`,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      }).catch(err => {
        this.$vs.notify({
          title: this.$t('notify.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  async created () {
    await this.fetchData()
    this.isMounted = true
  }
}

</script>

<style lang="scss">
#page-suppliers-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vs-table--header {
    display: flex;
    flex-wrap: wrap-reverse;
    // margin-left: 1.5rem;
    // margin-right: 1.5rem;
    > span {
      display: flex;
      flex-grow: 1;
    }

    .vs-table--search{
      padding-top: 0;

      .vs-table--search-input {
        padding: 0.9rem 2.5rem;
        font-size: 1rem;

        &+i {
          left: 1rem;
        }

        &:focus+i {
          left: 1rem;
        }
      }
    }
  }
}
</style>
